<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Información de la tienda" :breadcrumbs="breadcrumbs" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn v-if="$store?.state?.auth?.account?.country === 'PE'" color="blue-500" :to="{ name: 'LogsList', query: { branch: instance.id } }"><v-icon left>mdi-alert-circle</v-icon>Ver LOGS</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <VSkeleton v-if="$store.state.branches.retrieveLoader || $store.state.pos.retrieveLoader" />
    <template v-else>
      <v-col class="px-0" style="z-index: 0;">
        <div class="d-flex">
          <!-- detail card -->
          <div class="mr-6" style="min-width: 400px;">
            <v-card class="pa-0" width="400" flat transition="slide-y-transition" min-height="calc(100% - 150px)" style="position:fixed!important;">
              <VCardMapDetail :carouselList="['', instance.photo]" :itemList="cardMapDetail" @handleModal="handleModal" :instance="instance" :hiddenEdit="!$helpers.hasPermission(['change_branch'])" />
            </v-card>
          </div>
          <!-- detail card -->

          <!-- panels -->
          <div class="pb-5" style="flex:1; min-width: 180px;">
            <!-- statistics -->
            <v-card flat>
              <v-card-text class="px-5 py-0">
                <v-row justify="center" no-gutters>
                  <!-- status -->
                  <v-col cols="4" class="text-center px-0 py-5">
                    <span class="font-weight-bold body-1 grey-500--text">Estado</span>
                    <v-sheet class="d-flex align-center justify-center transparent mt-8">
                      <div>
                        <v-chip class="font-weight-semibold" color="blue-100" small label>Activo<v-icon right size="18">mdi-cloud</v-icon></v-chip>
                        <!-- <v-chip v-else-if="d.status === 'issued'" color="red lighten-4" text-color="red" small>Con problemas<v-icon right size="18">mdi-close</v-icon></v-chip>
                        <v-chip v-else-if="d.status === 'off'" color="defaultGrey" text-color="grey-500" small>Apagado<v-icon right size="18">mdi-cloud-off-outline</v-icon></v-chip> -->
                        <span class="mt-2 d-block grey-300--text body-1">{{editable.created | dateTime}}</span>
                      </div>
                    </v-sheet>
                  </v-col>
                  <!-- end status -->
                  <v-divider vertical />

                  <!-- percentage -->
                  <v-col cols="4" class="text-center py-5 px-0">
                    <span class="mb-4 d-block font-weight-bold body-1 grey-500--text">Porcentaje</span>
                    <v-progress-circular class="progress-circular-pos" rounded :rotate="360" :size="85" :width="10" :value="percent" color="blue-500">
                      <span class="d-block text-h6 grey-500--text font-weight-medium">{{posList.length}}</span>
                    </v-progress-circular>
                    <v-col cols="8" md="12" class="pa-0 pb-3 mx-auto">
                      <v-row class="mt-4 justify-center align-center">
                        <v-avatar class="pb-1" size="10" color="blue-500" />
                        <span :class="`${$vuetify.breakpoint.width > 1200 ? 'mr-4' : 'mr-1'}`" class="ml-1 body-2">Activos</span>
                        <v-avatar class="pb-1" size="10" color="var(--light-purple)" />
                        <span class="ml-1 body-2">Inactivos</span>
                      </v-row>
                    </v-col>
                  </v-col>
                  <!-- end percentage -->
                  <v-divider vertical />

                  <!-- documents issued -->
                  <v-col cols="4" class="text-center py-5 px-0">
                    <span class="font-weight-bold body-1 grey-500--text mx-auto">Cantidad de DTEs</span>
                    <!-- <v-sheet :height="$vuetify.breakpoint.width < 1000 ? 130 : 145" min-width="100" class="d-flex align-center justify-center transparent"> -->
                      <!-- <div> -->
                        <span class="d-block mt-6 grey-300--text body-1">$ 5.000 <span class="grey-300--text caption ml-1">{{$t('generals.CLP')}}</span></span>
                        <div class="mx-auto" :style="`width:${$vuetify.breakpoint.width < 1260 ? '160px' : '240px'}`">
                          <chart-line
                            class="d-block mx-6"
                            axe-type-y="number"
                            :background-color="'transparent'"
                            :colors="['#223AF8']"
                            :chart-data="chartLineData"
                            :custom-labels="['Total mes']"
                            :height="80"
                            :number-rounded="true"
                            :show-axe-x="false"
                            :show-draw-border-grid-lines-axe-y="false"
                            :show-grid-lines-axe-y="false"
                            :show-point="true"
                            :borderWidth="1"
                            style="position:relative;"
                          />
                        </div>
                      <!-- </div> -->
                    <!-- </v-sheet> -->
                  </v-col>
                  <!-- end documents issued -->
                </v-row>
              </v-card-text>
            </v-card>
            <!-- end statistics -->

            <!-- pos -->
            <v-expansion-panels class="mt-5" v-model="panelPos" v-if="$helpers.hasSomePermission(['view_pos'])">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-4 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <span class="subtitle-2 grey-500--text font-weight-bold"><v-icon size="18" left color="grey-500">mdi-printer-pos</v-icon>POS</span>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider />
                  <skeleton-list v-if="$store.state.pos.retrieveLoader" />
                  <template v-else>
                    <v-empty-state class="mt-n2 pb-2" v-if="!posList.length" type="documents" id="documentos" customClass="mt-2" :isFree="true" title="Aún no existen POS" :height="199" />                    <v-col class="pa-0" v-else>
                      <!-- <template class="text-center pb-5 pt-0"> -->
                        <v-simple-table class="no-hover pt-0 pb-1 px-2 table-not-rounded" dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left grey-500--text font-weight-medium" style="min-width: 220px;">Nombre</th>
                                <th class="text-left grey-500--text font-weight-medium" style="min-width: 200px;">ID</th>
                                <th class="text-left grey-500--text font-weight-medium" style="min-width: 70px;">Estado</th>
                              </tr>
                              <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -8px" />
                            </thead>
                            <tbody>
                              <tr v-for="item in posList" :key="item.id" @click="goToPos(item.id)" style="cursor:pointer">
                                <td class="body-2">
                                  <span class="d-inline-block text-truncate ml-n1" style="max-width: 100%;" v-if="item.custom_id">{{item.custom_id}}</span>
                                  <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                                </td>
                                <td class="text-left body-2" style="min-width: 180px;">
                                  <span class="d-inline-block text-truncate" style="max-width: 100%;">{{item.id}}</span>
                                </td>
                                <td class="text-left">
                                  <v-chip v-if="item" color="blue-100" small label>Activo<v-icon right size="18">mdi-cloud</v-icon></v-chip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <template v-if="posCount">
                          <v-divider />
                          <v-row class="px-8 py-4" align="center">
                            <v-btn class="body-1" :to="{name: 'PosListCreate', query: { branch: instance.id, name: instance.custom_id || instance.id }}" :ripple="false" text color="blue-500">Ver todos los POS</v-btn>
                          </v-row>
                        </template>
                      <!-- </template> -->
                    </v-col>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end pos -->

            <!-- documents -->
            <v-expansion-panels class="mt-5" v-model="panelDocuments">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-4 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <span class="subtitle-2 grey-500--text font-weight-bold"><v-icon size="18" left color="grey-500">mdi-file-document-multiple</v-icon>Documentos emitidos</span>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider />
                  <documents-list :loading="loading" :show-pagination="false" />
                  <template v-if="count && !loading">
                    <v-divider />
                    <v-row class="px-8 py-4" align="center">
                      <v-btn class="body-1" :to="{name: 'DocumentsList', query: { branch_id: instance.id,  name: instance.name || instance.custom_id, is_redirect: true }}" :ripple="false" text color="blue-500">Ver todos los documentos</v-btn>
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end documents -->
          </div>
          <!-- end panels -->
        </div>
      </v-col>
    </template>
    <!-- dialog edit branch -->
    <v-dialog v-model="dialogBranch" width="835" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-300--text font-weight-semibold">Editar tienda</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="handleModal" icon small><v-icon size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/background-branch-detail.svg`)" :height="128" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <v-list class="v-list-form-extended pa-0 transparent">
                <v-list-item class="py-0">
                  <v-list-item-title>Nombre</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model.trim="$v.editable.name.$model" :error="$v.editable.name.$error" outlined required single-line dense maxlength="100" hide-details />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-2">
                  <v-list-item-title>Dirección</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model.trim="$v.editable.address.$model" :error="$v.editable.address.$error" outlined required single-line dense maxlength="64" hide-details />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-0">
                  <v-list-item-title>{{$t('generals.Comuna')}}</v-list-item-title>
                  <v-list-item-subtitle v-if="$store.getters['base/currentStates']">
                    <v-autocomplete v-model="editable.state" :label="$t('generals.Comuna')" :items="$store.getters['base/currentStates'] || []" item-value="id" item-text="name" single-line hide-details dense height="30" outlined no-data-text="No existen estados para el país." />
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="handleModal">Cancelar</v-btn>
          <v-btn color="blue-500" @click="updateBranch" :disabled="isDisabledButton">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit branch -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength } from 'vuelidate/lib/validators'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import VEmptyState from '@/components/commons/VEmptyState'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ChartDoughnut from '@/components/charts/ChartDoughnut'
import ChartLine from '@/components/charts/ChartLine'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VCardMapDetail from '@/modules/pos/pos/components/complements/VCardMapDetail'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    MiniHeader,
    DocumentsList,
    ChartDoughnut,
    ChartLine,
    VEmptyState,
    VTableHeaders,
    VCardMapDetail,
    VSkeleton,
    SkeletonList
  },
  data: () => ({
    scroll: 0,
    isCreated: true,
    isDisabledButton: true,
    chartData: {
      labels: ["Pos activos", "Pos inactivos"],
      datasets: [
        {
          label: "Pos",
          data: [20, 35]
        }
      ]
    },
    // example
    chartLineData: {
      datasets: [{
        data: [346, 254, 435, 567, 234, 344, 767, 576, 786, 712, 543, 456],
        label: 'current',
        backgroundColor: '#304FFE',
        percent_change: [-13.88, 8.7, 10.93, 1.58, 3.72, 9.38, 20.78, -18.08, 2.93, 5.13, -3.37, 6.64]
      }],
      labels: ['2021-05-01', '2021-06-01', '2021-07-01', '2021-08-01', '2021-09-01', '2021-10-01', '2021-11-01', '2021-12-01', '2022-01-01', '2022-02-01', '2022-03-01', '2022-04-01']
    },
    // end expample
    branchesImages: ['', ''],
    panelPos: false,
    panelDocuments: false,
    loading: false,
    dialogBranch: false,
    editable: {
      custom_id: '',
      state: '',
      address: ''
    },
    pdfcopiesList: ['1', '2', '3', '4', '5'],
    sendingWayList: ['xml', 'txt'],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'ID', value: 'id' },
      { text: 'Estado', value: 'status_reason', width: 120}
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax',
    breadcrumbs: {
      main: 'POS ',
      children: [
        {
          text: 'Tienda',
          route: 'BranchListCreate'
        },
        {
          text1: 'Información de la tienda'
        }
      ]
    }
   }),
  computed: {
    ...mapState({
      posCount: state => state.pos.posCount,
      count: state => state.documents.documentsCount,
      instance: state => state.branches.branchesDetail,
      posList: state => state.pos.posList,
      documentsList: state => state.documents.documentsList
    }),
    cardMapDetail () {
      return [
        {
          title: 'Nombre',
          value: this.editable?.name ?? null
        },
        {
          title: 'Dirección',
          value: this.editable?.address ?? '-'
        },
        {
          title: this.$t('generals.Comuna'),
          value: this.$store.getters['base/currentStates']?.find(state => state.id === this.editable.state)?.name ?? ''
        },
        {
          title: 'ID',
          value: this.editable?.id ?? '-',
          isTooltip: true
        },
        {
          title: 'ID interno',
          value: this.editable?.custom_id ?? '-',
          isTooltip: true
        }
      ]
    },
    percent () {
      /* eslint-disable */
      return 100 // (this.posList.filter(({status_reason}) => status_reason === 'active').length * 100) / this.posList.length
    }
  },
  watch: {
    panelDocuments (val) {
      if (val === 0) this.getList()
    },
    editable: {
      handler () {
        if (!this.isCreated) {
          this.isDisabledButton = false
          this.isCreated = true
        }
      },
      deep: true
    },
    panelPos (val) {
      // if (val === 0) this.getPosList()
    }
  },
  created () {
    this.$store.dispatch('branches/RETRIEVE', {
      resource: 'branches',
      id: this.$route.params.id
    })
    .then(() => {
      this.editable = {...this.instance}
      if (this.$helpers.hasSomePermission(['view_pos'])) this.getPosList()
    })
  },
  methods: {
    goToPos (id) {
      this.$router.push({ name: 'PosRetrieveUpdate' , params: { id: id } })
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    async getList () {
      this.loading = true
      await this.$store.dispatch('documents/LIST', {
        resource: 'documents',
        query: {
          page_size: 6,
          branch_id: this.instance.id
        }
      })
      this.loading = false
    },
    getPosList () {
      this.$store.dispatch('pos/LIST', {
        resource: 'pos',
        query: {
          branch: this.instance.id,
          page_size: 6
        }
      })
    },
    handleModal () {
      this.isCreated = !this.isCreated
      this.dialogBranch = !this.dialogBranch
      this.isDisabledButton = true
      if (!this.dialogBranch) {
        this.editable = {...this.instance}
      }
    },
    updateBranch () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }

      this.$store.dispatch('branches/UPDATE', {
        resource: 'branches',
        id: this.$route.params.id,
        payload: {
          name: this.editable.name,
          address: this.editable.address,
          state: this.editable.state
        }
      })
      .then(() => {
        this.$dialog.message.info('Los datos de la tienda fueron actualizados correctamente')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogBranch = false
      })
    }
  },
  validations: {
    editable: {
      name: { required },
      // state: { required },
      address: { required, maxLength: maxLength(254) }
    }
  }
}
</script>